import { Box, styled } from "@mui/material";
import { FC, Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSideBar";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: '100vw',
  margin: "auto",
  top: 0,
  right: 0,
  left: 0,
  [theme.breakpoints.down("md")]: {
    maxWidth: '100vw',
    margin: "auto",
    top: 0,
    right: 0,
    left: 0,
  },
}));

const DashboardLayout: FC = ({ children }) => {
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  return (
    <Fragment>
      <DashboardSidebar
        showMobileSideBar={showMobileSideBar}
        closeMobileSideBar={() => setShowMobileSideBar(false)}
      />

      <Wrapper>
        <DashboardNavbar
          setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
        />
        <Box display={'flex'} justifyContent={'center'}>
            {children || <Outlet />}
        </Box>
      </Wrapper>
    </Fragment>
  );
};

export default DashboardLayout;
