import {
  AppBar,
  Box,
  Grid,
  Menu,
  MenuItem,
  styled,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useState, } from "react";
import LanguagePopover from "./popovers/LanguagePopover";
import NotificationsPopover from "./popovers/NotificationsPopover";
import ProfilePopover from "./popovers/ProfilePopover";
import ServicePopover from "./popovers/ServicePopover";
import { H2 } from "../Typography";
import { ArrowDropDown } from "@mui/icons-material";
import { useGlobalState } from "../../data-context";

// root component interface
interface DashboardNavBarProps {
  setShowMobileSideBar: () => void;
}

// custom styled components
const DashboardNavbarRoot = styled(AppBar)(() => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(8px)",
  backgroundColor: "#f3f7fd39",
}));

const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 100,
    paddingRight: 100,
    minHeight: "auto",
  },
}));

const ToggleIcon = styled(Box)(({ theme }) => ({
  width: 25,
  height: 3,
  margin: "5px",
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.main,
}));

// root component
const DashboardNavbar: FC<DashboardNavBarProps> = ({
  setShowMobileSideBar,
}) => {
  const { state, setState } = useGlobalState();
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  if (downSm) {
    return (
      <DashboardNavbarRoot position="sticky" sx={{minWidth: '100vw',}}>
        <StyledToolBar >
          <Box sx={{ cursor: "pointer" }} onClick={setShowMobileSideBar}>
            <ToggleIcon />
            <ToggleIcon />
            <ToggleIcon />
          </Box>

          <Box flexGrow={1} textAlign="center">
            <img
              src="/images/icon.png"
              width="100%"
              height="30"
              alt="Logo"
            />
          </Box>

          {/* <LanguagePopover /> */}
          <ProfilePopover />
        </StyledToolBar>
      </DashboardNavbarRoot>
    );
  }

  return (
    <DashboardNavbarRoot position="sticky">
      <StyledToolBar >
        <Grid display={'flex'} sx={{ border:(theme) =>  `1px dashed ${theme.palette.primary.main}`, borderRadius: '8px', p: '2px', pr: '6px' }} direction={'row'} onClick={handleClick}>
          <Grid xs={2} display={'flex'} alignItems={'center'} justifyContent={'center'} item>
            <ArrowDropDown sx={{color: (theme) => theme.palette.primary.main, fontSize: 20}}/>
          </Grid>
          <Grid xs={10} item>
            <Typography  fontSize={16} fontWeight="400"  color="text.primary"   >
              {state?.currentEvent?.eventTitle ? state.currentEvent.eventTitle : state?.org?.name || 'Organization'}
          </Typography>
          </Grid>
        
        </Grid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {state?.events?.map((e, index) => (
          <MenuItem key={index} onClick={() => {
            setState(prevState => ({ ...prevState, currentEvent: e }));
            handleClose();
          }}>
            {e.eventTitle}
          </MenuItem>
        ))}
      </Menu>
        <Box flexGrow={1} ml={1} />

        {upSm && (
          <>
            {/* <LanguagePopover /> */}
            <NotificationsPopover />
            <ServicePopover />
          </>
        )}
        <ProfilePopover />
      </StyledToolBar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
