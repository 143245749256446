import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const KonectoLogo = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 773 226" >
        <path d="M100.8,59.4c1.9,0,3.5,0.7,5,2.1c1.4,1.4,2.2,2.9,2.2,4.7c0,1.8-0.8,3.4-2.4,4.9l-48.1,42.3l52.1,54.1  c1.6,2,2.4,3.9,2.4,5.6c0,1.6-0.6,3-1.9,4.1s-2.8,1.7-4.5,1.7c-2.3,0-4.3-0.9-6-2.6l-51.7-54.5L28,139.3v33.1c0,2-0.7,3.6-2.1,4.8  s-3,1.8-4.9,1.8s-3.5-0.6-4.8-1.8s-2-2.8-2-4.8V65.8c0-2,0.7-3.6,2-4.7c1.3-1.1,2.9-1.7,4.8-1.7s3.5,0.6,4.9,1.7  c1.4,1.1,2.1,2.7,2.1,4.7v58.3l18.2-18.4l49.4-44C97.3,60.2,99,59.4,100.8,59.4z" />
        <path d="M147.3,174.1c-7.5-4.5-13.2-10.6-17.3-18.3c-4.1-7.7-6.1-16.3-6.1-25.7c0-9,2.1-17.2,6.4-24.5s10.1-13.1,17.6-17.3  c7.5-4.2,15.8-6.3,25.1-6.3s17.7,2.2,25.2,6.6s13.4,10.3,17.6,17.9c4.2,7.5,6.3,15.7,6.3,24.6c0,9.4-2.1,17.9-6.3,25.5  s-10.1,13.5-17.6,17.9c-7.5,4.3-15.9,6.5-25.2,6.5C163.3,180.9,154.8,178.6,147.3,174.1z M155.6,99c-5.3,2.8-9.6,7-12.9,12.6  c-3.3,5.6-4.9,12.3-4.9,20c0,6.6,1.5,12.8,4.4,18.3c2.9,5.6,7.1,10,12.4,13.2c5.3,3.2,11.4,4.8,18.3,4.8c6.8,0,12.8-1.7,18.2-5  s9.6-7.7,12.6-13.3c3-5.5,4.5-11.4,4.5-17.7c0-8-1.7-14.9-5.1-20.5c-3.4-5.6-7.8-9.8-13.2-12.6c-5.4-2.8-11.2-4.1-17.3-4.1  C166.6,94.8,160.9,96.2,155.6,99z" />
        <path d="M319.9,109c-2.7-4.4-6.3-7.9-10.7-10.4c-4.5-2.5-9.2-3.8-14.4-3.8c-6,0-11.8,1.5-17.2,4.6c-5.5,3.1-9.8,7.3-13.2,12.8  c-3.3,5.5-5,11.5-5,18.1v42.1c0,2.1-0.6,3.8-1.8,4.9c-1.2,1.1-2.9,1.7-5,1.7s-3.9-0.6-5.2-1.8s-2-2.8-2-4.8v-82c0-2,0.7-3.6,2-4.8  c1.3-1.2,2.9-1.8,4.8-1.8c1.9,0,3.5,0.6,4.8,1.8c1.3,1.2,2,2.9,2,5v10.9c3.3-6.5,8.1-11.4,14.6-14.7c6.5-3.3,13.6-4.9,21.3-4.9  c7.4,0,14.3,1.7,20.9,5c6.5,3.3,11.8,8.2,15.8,14.6s6,13.9,6,22.6v48.3c0,2.1-0.6,3.8-1.8,4.9c-1.2,1.1-2.9,1.7-5,1.7  s-3.8-0.6-5.1-1.8c-1.3-1.2-1.9-2.8-1.9-4.8v-48.3C323.9,118.5,322.6,113.4,319.9,109z" />
        <path d="M451.2,139.5h-74.3c0.9,5.3,2.8,10.1,5.9,14.4c3.1,4.3,7.1,7.8,12,10.4c4.9,2.6,10.4,3.9,16.5,3.9c9.8,0,18.1-2.6,25-7.9  c1.5-1.4,3.3-2.1,5.3-2.1c1.8,0,3.3,0.6,4.5,1.7c1.3,1.1,1.9,2.5,1.9,4.1c0,1.9-0.9,3.7-2.6,5.5c-4.1,3.9-9.2,6.8-15,8.6  c-5.9,1.9-12,2.8-18.4,2.6c-9.7,0-18.2-2.2-25.7-6.5s-13.2-10.3-17.2-17.9c-4-7.6-6-16.1-6-25.5c0-10.2,2.3-18.9,6.8-26.3  s10.4-13,17.8-16.9c7.3-3.9,15.1-5.8,23.4-5.8c8.8,0,16.9,1.8,24.3,5.3c7.5,3.5,13.5,8.9,18,16.3c4.6,7.3,6.9,16.5,7,27.4  C460.4,136.6,457.3,139.5,451.2,139.5z M447.3,128.1c-0.6-7.1-2.7-13.2-6.2-18.2s-7.9-8.8-13.1-11.3s-10.6-3.8-16.1-3.8  c-9.4,0-17.5,2.9-24.3,8.6s-10.5,13.9-11.3,24.7H447.3z" />
        <path d="M542,96.9c-4.4-1.8-9-2.7-13.9-2.7c-6.5,0-12.3,1.6-17.5,4.9c-5.1,3.3-9.2,7.7-12,13.4c-2.9,5.7-4.3,12.1-4.3,19.1  c0,6.8,1.4,12.9,4.3,18.5s6.9,10,12.1,13.3c5.2,3.3,11.2,5,18,5c5.3,0,10.3-1,15.1-3.1s8.8-5.2,11.9-9.5c1.1-1.6,2.6-2.4,4.5-2.4  c1.8,0,3.3,0.6,4.7,1.8s2.1,2.7,2.1,4.4c0,1.5-0.6,2.9-1.9,4.3c-4.4,5.4-9.8,9.6-16.4,12.5c-6.5,2.9-13.3,4.4-20.3,4.4  c-9.3,0-17.5-2.2-24.8-6.6c-7.3-4.4-12.9-10.3-17-17.9c-4.1-7.5-6.1-15.9-6.1-25.2c0-7.3,1.6-14.7,4.8-22.2  c3.2-7.5,8.4-13.9,15.7-19.2c7.3-5.3,16.7-7.9,28.4-7.9c6.8,0,12.9,1.3,18.4,3.8s10.7,6.5,15.6,12c1,1,1.5,2.3,1.5,3.8  c0,1.6-0.7,3.1-2.1,4.3c-1.4,1.3-2.9,1.9-4.7,1.9c-1.6,0-3.1-0.8-4.5-2.3C550.3,101.6,546.4,98.8,542,96.9z" />
        <path d="M634.3,85.6c1.1,1.1,1.7,2.4,1.7,3.9c0,1.6-0.6,3-1.7,4.1c-1.1,1.1-2.7,1.7-4.7,1.7h-20.5v51.1c0,6.6,1,12,2.9,16  s5.8,6,11.6,6c1.4,0,3-0.2,4.8-0.7c1.8-0.4,3.2-0.9,4-1.4c1.4-0.8,2.6-1.1,3.8-1.1c1.6,0,3,0.6,4.2,1.9c1.2,1.3,1.8,2.7,1.8,4.3  c0,2.5-1.4,4.6-4.1,6.2c-3.8,2.1-8.6,3.2-14.5,3.2c-18.7,0-28-11.5-28-34.4V95.3h-12.4c-2,0-3.6-0.6-4.7-1.7  c-1.1-1.1-1.7-2.4-1.7-3.9s0.6-2.8,1.7-4c1.1-1.2,2.7-1.8,4.7-1.8h12.4V61.5c0-2.1,0.7-3.8,2-5s2.9-1.8,4.8-1.8  c1.9,0,3.5,0.6,4.8,1.8s2,2.9,2,5v22.4h20.5C631.6,83.9,633.2,84.4,634.3,85.6z" />
        <path d="M746.5,124.5c-1.7-0.4-3.2-0.1-4.4,0.8c-1.7,1.3-2.7,3.8-3,7.6c-0.9,12.7-6.5,22.3-16.8,28.8c-15.3,9.6-33.8,7.6-46-4.8  c-9.8-10-13-23.8-8.5-37.1c4.5-13.4,15.5-22.5,29.5-24.6c6.5-0.9,7.6-1.2,8.8-2.7c1.3-1.6,1.8-3.8,1.4-5.8c-0.4-1.7-1.5-3.1-3-3.8  c-6.9-3-24,2.5-33.9,11c-10.1,8.7-16.4,21.4-17.1,35.1c-0.7,13.2,3.9,26.2,12.6,35.5c9.8,10.4,22.6,15.9,36,15.9  c4.8,0,9.6-0.7,14.4-2.1c18.2-5.4,31-19.9,34.4-38.8C752.2,131.1,752,125.7,746.5,124.5z" />
        <path d="M763.6,106.8C760,90,747.2,75.6,731.7,70.9c-6.5-1.9-15.2-3.1-18.5-1.6c-2.1,0.9-3.7,4.8-3.1,7.1c0.9,3.4,3,4.1,9.6,4.9  c17.8,2.1,29.9,14.1,32.3,32.2c0.8,5.8,1.9,8.6,6.1,8.6c0.3,0,0.6,0,0.9,0c1.8-0.1,3.2-0.9,4.1-2.1  C764.8,117.6,765,113.4,763.6,106.8z" />
    </SvgIcon>
);

export default KonectoLogo;
