import { Dashboard, DashboardCustomizeOutlined, LocationCityOutlined, PeopleAltRounded, Web } from "@mui/icons-material";
import { Icon } from "@mui/material";

const index = [
  {
    title: "Dashboard",
    Icon: Dashboard,
    path: "/dashboard",
  },
  {
    title: "User Profile",
    Icon: DashboardCustomizeOutlined,
    path: "/dashboard/user-profile",
  },
  {
    title: "User Grid",
    Icon: DashboardCustomizeOutlined,
    path: "/dashboard/user-grid",
  },
  {
    title: "User List",
    Icon: DashboardCustomizeOutlined,
    path: "/dashboard/user-list",
  },
  {
    title: "Team",
    Icon: PeopleAltRounded,
    path: "/dashboard/team",
  },
  {
    title: "To Do List",  
    Icon: () => <Icon> <img width={24} height={24} src={'/icons/kanban.png'} alt="Kanban Icon" /></Icon>,
    path: "/dashboard/kanban",
  },
  {
    title: "Landing Page Builder",
    Icon: Web,
    path: "/dashboard/builder",
  },
  {
    title: "Search Venue",
    Icon: LocationCityOutlined,
    path: "/dashboard/search",
  },
];

export default index;
