import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const KonectoIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 160 160" >
        <path d="M117.52,79.99c-1.7-.4-3.2-.1-4.4,.8-1.7,1.3-2.7,3.8-3,7.6-.9,12.7-6.5,22.3-16.8,28.8-15.3,9.6-33.8,7.6-46-4.8-9.8-10-13-23.8-8.5-37.1,4.5-13.4,15.5-22.5,29.5-24.6,6.5-.9,7.6-1.2,8.8-2.7,1.3-1.6,1.8-3.8,1.4-5.8-.4-1.7-1.5-3.1-3-3.8-6.9-3-24,2.5-33.9,11-10.1,8.7-16.4,21.4-17.1,35.1-.7,13.2,3.9,26.2,12.6,35.5,9.8,10.4,22.6,15.9,36,15.9,4.8,0,9.6-.7,14.4-2.1,18.2-5.4,31-19.9,34.4-38.8,1.3-8.4,1.1-13.8-4.4-15Z" />
        <path d="M134.62,62.29c-3.6-16.8-16.4-31.2-31.9-35.9-6.5-1.9-15.2-3.1-18.5-1.6-2.1,.9-3.7,4.8-3.1,7.1,.9,3.4,3,4.1,9.6,4.9,17.8,2.1,29.9,14.1,32.3,32.2,.8,5.8,1.9,8.6,6.1,8.6h.9c1.8-.1,3.2-.9,4.1-2.1,1.7-2.4,1.9-6.6,.5-13.2Z" />
    </SvgIcon>
);

export default KonectoIcon;