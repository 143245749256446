
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./components/Layouts/DashboardLayout";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/authentication/AuthGuard";
import React from "react";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));
const EventDashboard = Loadable(lazy(() => import("./pages/dashboards/event-dashboard")));

// Venue Search
const VenueSearch = Loadable(lazy(() => import("./venue-search/search")));

// Landing page builder
const LandingPageBuilder = Loadable(lazy(() => import("./web-builder/builder")));

// Create event flow
const CreateEventFlow = Loadable(lazy(() => import("./pages/create-event/parent")));

// Dashboard pages
const JoinOrganization = Loadable(lazy(() => import("./registration/index")));

// team management boards src/pages/dashboards/trello-board.tsx
const Trello = Loadable(lazy(() => import("./pages/dashboards/trello-board")));


// team management boards src/pages/dashboards/trello-board.tsx
const TeamManagement = Loadable(lazy(() => import("./pages/dashboards/team")));


// user profile 
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));

// user management
const UserList = Loadable(
  lazy(() => import("./pages/userManagement/UserList"))
);
const UserGrid = Loadable(
  lazy(() => import("./pages/userManagement/UserGrid"))
);
const AddNewUser = Loadable(
  lazy(() => import("./pages/userManagement/AddNewUser"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
        <Login />
    ),
  },
  {
    path: "register",
    element: (
        <Register />
    ),
  },
  {
    path: "forget-password",
    element: (
        <ForgetPassword />
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "event",
        element: <EventDashboard />,
      },
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "new/event",
        element: <CreateEventFlow />,
      },
      {
        path: "kanban",
        element: <Trello />,
      },
      {
        path: "team",
        element: <TeamManagement />,
      },

      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "user-grid",
        element: <UserGrid />,
      },
      {
        path: "builder",
        element: < LandingPageBuilder/>,
      },
      {
        path: "search",
        element: <VenueSearch />,
      },
      {
        path: "steps",
        element: <JoinOrganization />,
      },
      { 
        path: "*", element: <Error /> 
      } 
    ],
  },
  // 404 route outside of dashboard for non-dashboard paths
  { path: "*", element: <Error /> } 
];

export default routes;
