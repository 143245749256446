import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Login from "../../pages/authentication/Login";
import { fAuth } from "../../firebase/auth/init";
import { useGlobalState } from "../../data-context";

// component props interface
interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const [user, setUser] = useState<any>(null);
  const { pathname } = useLocation();
  const { state, setState } = useGlobalState();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  useEffect(() => {
    const unsubscribe = fAuth.onAuthStateChanged((user) => {
      setUser(user);
      setState((prevState: any) => ({ ...prevState, loading: false }));
    });

    return () => unsubscribe();
  }, []);

  if (!user) {
    if (pathname !== requestedLocation) {
      setState((prevState: any) => ({ ...prevState, loading: false }));
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
