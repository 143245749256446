import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig } from 'firebase/remote-config';
import { getDatabase } from 'firebase/database';


export const firebaseConfig = {
  apiKey: "AIzaSyCcnPFEDw2lL5Ul8mHz8prYB8l46VpklVw",
  authDomain: "konecto-me.firebaseapp.com",
  projectId: "konecto-me",
  storageBucket: "konecto-me.appspot.com",
  messagingSenderId: "969215617072",
  appId: "1:969215617072:web:362f80c43cd9c7966c65f5",
  measurementId: "G-N7GCH3SM9N"
};


// Initialize Firebase app
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

// initialize firestore database instance and firebase storage for images 
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const messaging = getMessaging(app);
export const performance = getPerformance(app);
export const remoteConfig = getRemoteConfig(app);
export const database = getDatabase(app);

