import "nprogress/nprogress.css";
import ReactDOM from "react-dom";
import "react-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import App from "./App";

ReactDOM.render(
          <BrowserRouter>
            <App />
          </BrowserRouter>
  ,
  document.getElementById("root")
);
