import { GoogleAuthProvider, createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { app } from "../init";

export const fAuth = getAuth(app)

export const loginWithEmail = (email: string, password: string) => {
    return signInWithEmailAndPassword(fAuth, email, password);
};

export const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(fAuth, provider);
};

export const registerWithEmail = (email: string, password: string) => {
    return createUserWithEmailAndPassword(fAuth, email, password);
};

export const resetPassword = (email: string) => {
    return sendPasswordResetEmail(fAuth, email);
};