import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { fAuth } from "../../firebase/auth/init";
import { db } from "../../firebase/init";

export const updateCurrentUserProfile = async (profileData, success?, failure?) => {
    ifAuthenticatedDo(async (uid) => {
      try {
        const docRef = doc(db, 'users', uid);
        const d = await setDoc(docRef, profileData, { merge: true });
        if (success) { success(d); }
      } catch (error) {
        failure(error)
      }
    });
  };



 export  const ifAuthenticatedDo = (callback) => { 
    const uid = localStorage.getItem('uid');
    if (uid) {
      callback(uid);
    } else {
      toast.error('User not authenticated');
      fAuth.signOut();
    }
  }
  

  export const getUserDetails = async (success, failure) => {
    ifAuthenticatedDo(async (uid) => {
      const docRef = doc(db, 'users', uid);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          success(docSnap.data());
        } else {
          console.log('No such document!');
          success(null);
        }
      } catch (error) {
        console.log('Error getting user details:', error);
        failure(error);
      }
    },);
  };
  
  export const getUserOrganization = async (success, onFailure?) => {
    ifAuthenticatedDo(async (uid) => {
      try {
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const user = docSnap.data();
          if (user.organizationId) {
            const orgDocSnap = await getDoc(doc(db, 'organizations', user.organizationId));
            if (orgDocSnap.exists()) {
              success(user.organizationId, orgDocSnap.data());
            } 
          }
        }
    
      } catch (e) { if (onFailure) {onFailure(e)} else {fAuth.signOut()}}
    });
  };

  
  export const getEventsForOrganization = async (organizationId: string, onSuccess, onFailure?) => {
    try {
      const eventsRef = collection(db, 'organizations', organizationId, 'events');
      const eventsQuerySnapshot = await getDocs(eventsRef);
      const events = eventsQuerySnapshot.docs.map(doc => ({
        ...doc.data(),
      }));
      onSuccess(events);
    } catch (e) { if (true) {toast.error(e)} else {fAuth.signOut()}}
  }


  export const getUserOrganizationEvents = async (onSuccess, onFailure?) => {
    let [user, org, events] = [null, null, null]
    getUserDetails(async (userData: any) => {
      user = userData;
      getUserOrganization(async (id: string, orgData: any) => {
        org = orgData;
        getEventsForOrganization(id, async (eventData: any) => {
          events = eventData;
          onSuccess({ user, org, events });
        }, (e) => { if (true) { toast.error(e); } else { fAuth.signOut();}});
      }, (e) => { if (true) { toast.error(e); } else { fAuth.signOut();}});
    },  (e) => { if (true) { toast.error(e); } else { fAuth.signOut();}});

  };
  