import { createContext, useContext, useEffect, useState } from "react";
import { getUserOrganizationEvents } from "./registration/componenets/static-logic";
import { LoadingView } from "./components/Layouts/loading";

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [state, setState] = useState(() => {
    // Retrieve state from localStorage if available
    const savedState = localStorage.getItem("dataState");
    return savedState ? JSON.parse(savedState) : { user: {}, org: {}, events: [], notifications: [], isLoading: false };
  });

  useEffect(() => {
    getUserOrganizationEvents(({ user, org, events }) => {
      setState(prevState => ({ ...prevState, user, org, events, notifications: [], isLoading: false }));
    });
  }, []); // Run only once on component mount

  // Save state to localStorage when component unmounts
  useEffect(() => {
    return () => {
      localStorage.setItem("dataState", JSON.stringify(state));
    };
  }, [state]);

  return (
    <DataContext.Provider value={{ state, setState }}>
      {state.isLoading ? (
        <LoadingView />
      ) : (
        <>{children}</>
      )}
    </DataContext.Provider>
  );
};

export const useGlobalState = () => useContext(DataContext);
