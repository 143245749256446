import { SvgIcon, SvgIconProps } from "@mui/material";

const GoogleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8444 6.4332H15.2V6.4H8V9.6H12.5212C11.8616 11.4628 10.0892 12.8 8 12.8C5.3492 12.8 3.2 10.6508 3.2 8C3.2 5.3492 5.3492 3.2 8 3.2C9.2236 3.2 10.3368 3.6616 11.1844 4.4156L13.4472 2.1528C12.0184 0.8212 10.1072 0 8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 7.4636 15.9448 6.94 15.8444 6.4332Z"
        fill="#FFC107"
      />
      <path
        d="M0.921875 4.2764L3.55028 6.204C4.26148 4.4432 5.98388 3.2 7.99948 3.2C9.22308 3.2 10.3363 3.6616 11.1839 4.4156L13.4467 2.1528C12.0179 0.8212 10.1067 0 7.99948 0C4.92668 0 2.26188 1.7348 0.921875 4.2764Z"
        fill="#FF3D00"
      />
      <path
        d="M7.99993 15.9995C10.0663 15.9995 11.9439 15.2087 13.3635 13.9227L10.8875 11.8275C10.0573 12.4588 9.04291 12.8003 7.99993 12.7995C5.91913 12.7995 4.15233 11.4727 3.48673 9.62109L0.87793 11.6311C2.20193 14.2219 4.89073 15.9995 7.99993 15.9995Z"
        fill="#4CAF50"
      />
      <path
        d="M15.8444 6.43359H15.2V6.40039H8V9.60039H12.5212C12.2057 10.487 11.6373 11.2617 10.8864 11.8288L10.8876 11.828L13.3636 13.9232C13.1884 14.0824 16 12.0004 16 8.00039C16 7.46399 15.9448 6.94039 15.8444 6.43359Z"
        fill="#1976D2"
      />
    </SvgIcon>
  );
};

export default GoogleIcon;
