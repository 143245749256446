import { Avatar, Badge, Box, ButtonBase, Divider, styled } from "@mui/material";
import { FC, Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PopoverLayout from "./PopoverLayout";
import { H6, Small, Tiny } from "../../Typography";
import UkoAvatar from "../../UkoAvatar";
import FlexBox from "../../FlexBox";
import { signOut } from "firebase/auth";
import { fAuth } from "../../../firebase/auth/init";
import { useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from "../../../firebase/init";
import { use } from "i18next";

// styled components
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.light
        : theme.palette.divider,
  },
}));

const ProfilePopover: FC = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(null); // Initialize with null
  const [open, setOpen] = useState(false);
  
  
  useEffect(() => {
    const fetchData = async () => {
      const userAuth = fAuth.currentUser;
        if (userAuth?.uid) { // Ensure userAuth exists and has a UID
            const docRef = doc(db, 'users', userAuth.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUser(docSnap.data());
            } else {
                console.log("User document not found."); 
            }
        }
    };

    fetchData();
}, [setUser]); 



  const handleMenuItem = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "7%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
          }}
        >
          <Avatar 
              alt={user?.firstName} src={user?.avatar } variant="circular"
              sx={{ 
              alignSelf: 'center', width: 30, height: 30, ml: 1 ,
              fontSize: '36px', fontWeight: 'bold', backgroundColor: 'primary.main', }}
          >
            {user?.firstName.charAt(0)}
          </Avatar> 

        </Badge>
      </ButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center">

          <Avatar 
              alt={user?.firstName} src={user?.avatar } variant="circular"
              sx={{ 
              alignSelf: 'center', width: 50, height: 50, ml: 1 ,
              fontSize: '36px', fontWeight: 'bold', backgroundColor: 'primary.main', }}
          >
            {user?.firstName.charAt(0)}
          </Avatar> 
            
            <Box ml={1}>
              <H6>{user?.firstName} {user?.lastName}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {user?.email}
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>
          <StyledSmall
            onClick={() => handleMenuItem("/dashboard/user-profile")}
          >
            Settings
          </StyledSmall>

          <StyledSmall
            onClick={() => handleMenuItem("/dashboard/user-profile")}
          >
            Organization
          </StyledSmall>

          <StyledSmall
            onClick={() => handleMenuItem("/dashboard/user-profile")}
          >
            Billing
          </StyledSmall>

          <StyledSmall
            onClick={() => handleMenuItem("/dashboard/user-profile")}
          >
            Profile
          </StyledSmall>

          <Divider sx={{ my: 1 }} />

          <StyledSmall
            onClick={() => {
              signOut(fAuth).then(() => {
              toast.error("You Logout Successfully");
              navigate("/login");
            });}}
          >
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
